/* CategoriaOptions styles */
.categoria-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .options-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .categoria-option {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .categoria-option:hover {
    background-color: #e0e0e0;
  }
  
  .back-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #45a049;
  }
  
  /* ServiciosOptions styles */
  .servicios-options-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
  }
  
  .servicio-option {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .servicio-option:hover {
    background-color: #e0e0e0;
  }
  
  /* Chat message styles */
  .servicio-seleccionado {
    background-color: #e6f3ff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .servicio-seleccionado h3 {
    margin-top: 0;
    color: #0066cc;
  }
  
  .servicio-seleccionado p {
    margin-bottom: 5px;
    color: #333;
  }

.react-chatbot-kit-chat-message-container {
  background-color: #f0f0f0;
  color: #333;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #0084ff;
  color: white;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #0084ff;
}

.react-chatbot-kit-chat-bot-avatar-container {
  background-color: #0084ff;
}

.react-chatbot-kit-user-chat-message {
  background-color: #e6e6e6;
  color: #333;
}

.react-chatbot-kit-user-chat-message-arrow {
  border-left: 8px solid #e6e6e6;
}